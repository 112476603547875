<template>
    <div id="section">
        <div class="container">

            <div id="hero" v-bind:style="{ 'background-image': 'url(storage/' + hero + ')' }">
                <div class="hero-content text-center">
                    <h1 class="hero-title" v-html="header"></h1>
                </div>
            </div>

            <div class="section-content" v-html="body"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['body', 'programma', 'hero', 'header'],
};
</script>

<style lang="scss">

$black : #00212A;

#section {

    h2, h3, h4, h5{
        font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
        color: $black;
    }

    .section-content{
        padding: 20px;
    }

    #hero{
        background-size: 100%;
        background-position: center 60%;
        background-repeat: no-repeat;
        height: 200px;

        .hero-title{
            font-family: "Arial Black", "Arial Bold", Gadget, sans-serif; 
            color: $black;
            font-size: 2.6em;
            padding: 10px 20px 5px;
            line-height: 1.3;
            width: 50%;
            margin: auto;

            span{
                background-color: #3DC9ED;
                padding: 0 10px;
            }
        }

        .hero-logo{
            height: 80px;
            padding: 5px;
        }

    }

}

@media only screen and (max-width: 768px) {
    #section{
        padding: 0;

        #hero{
            .hero-title{
                font-size: 1.5em;
                width: 100%;
                padding: 30px 20px 0;
            }
        }

    }
}

</style>
