<template>
    <main>
        <section-component :body="page.body" :programma="programma" :hero="page.image" :header="page.image_text" />
        <search-component :programma="programma" />
    </main>
</template>

<script>

import SectionComponent from './SectionComponent.vue';
import SearchComponent from './SearchComponent.vue';

export default {
    props: ['page', 'programma'],
    components: {
        'section-component': SectionComponent,
        'search-component': SearchComponent
    }
};
</script>