<template>
	<div id="section-search">

		<div class="container">

			<div class="search-content">

                <div v-if="formErrors">
                    <div class="alert alert-danger" role="alert" v-for="error in formErrors" :key="error">
                        <font-awesome-icon icon="exclamation-circle" />
                        {{ error[0] }}
                    </div>
                </div>

				<search-input @searchSubmit="search" @querySearch="querySearch" />

                <div class="exact-search">
                    <input type="checkbox" id="exact-search" v-model="exactSearch"> <label for="exact-search">Zoek Exact</label>
                </div>

                <div class="term-list">
                    <strong>Suggesties voor zoektermen:</strong>

                    <a class="term-item" v-for="(term, index) in popularTerms" :key="index" @click="setSearch(term.key)">
                        <span class="badge badge-info">
                            {{ term.key }}
                        </span>
                    </a>
                </div>

				<strong>Partijen</strong>
				<search-filter @filterselected="setFilters" :programma="programma" />

			</div>

		</div>

        <div id="results">
            <party-highlight v-if="results[0]" :results="results" />
        </div>

	</div>
</template>

<script>

import SearchInputComponent from './children/SearchInputComponent.vue';
import SearchFilterComponent from './children/SearchFilterComponent.vue';
import PartyHighlightComponent from './children/PartyHighlightComponent.vue';

export default {

	components: {
		'search-input': SearchInputComponent,
		'search-filter': SearchFilterComponent,
		'party-highlight': PartyHighlightComponent,
	},

	props: ['programma'],

	data() {

		return {
			items: this.partyName,
			filters: [],
			query: '',
            exactSearch: false,
			results: {},
            popularTerms: [],
            popularTermsSize: 10,
            formErrors: null,
            windowHeight: window.innerHeight,
            heightTxt: '',
            scrollpx: 0
		}

	},

	methods: {

		setFilters(filters) {
			this.filters = filters;

            if(this.query && filters.length > 1) {
                this.search(false);
            }
		},

		querySearch(query) {
			this.query = query;
		},

		async search(scroll = true) {
            try {
                let { data } = await axios.get('/search', {
                    params: {
                        filters: this.filters,
                        query: this.query,
                        ...this.exactSearch && { exact: this.exactSearch }
                    }
                });

                this.formErrors = null;
                this.results = data.hits.hits;

                this.$root.$emit('set-active-ids', this.results);

                if(scroll)
                    this.scrollTo();

            } catch (error) {
                this.formErrors = error.response.data?.errors;
            }
		},
        async getPopularTerms() {
            const { data } = await axios.get('/popular', {
                params: {
                    size: this.popularTermsSize
                }
            });

            this.popularTerms = data?.aggregations?.popularTerms?.buckets;
        },

        setSearch(term) {
            this.query = term;

            this.$root.$emit('set-search-term', term);
        },

        scrollTo(){
            let anchor = document.querySelector('#results');
            anchor.scrollIntoView({ block: 'start', behavior : 'smooth'})
        }

	},

	mounted() {
        this.getPopularTerms();
        this.$root.$on('global-filters', this.setFilters);
    }
}

</script>

<style lang="scss" scoped>

#section-search{
	background-color: #F6F6F6;
	padding: 40px 0;

	.search-content{
		background-color: #eeeeee;
		padding: 40px;
		strong{
			padding: 20px;
		}
	}

    .term-list {
        padding: 10px 25px;
        text-align: right;

        .term-item {
            margin: 0 5px;
            cursor: pointer;
            font-size: 1.2em;
            font-weight: 600;
            .badge{
                background-color: #f1f1f1;
                padding: 5px 10px;
                font-weight: 300;
                border: 1px solid  #d8d8d8;
                &:hover{
                    background-color: #d8d8d8;
                }
            }
        }

    }

    #results{
        min-height: 300px;
        margin-bottom: 80px;

    }

}
.exact-search{
    padding: 15px 10px 0;
}

@media only screen and (max-width: 768px) {

    #section-search{

        .search-content{
            padding: 20px;
            strong{
                padding : 5px 11px;
            }

        }
        .term-list {
            padding: 10px;
            text-align: left;
            strong{
                padding : 5px 0;
            }
        }

    }

}
</style>
