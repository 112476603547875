<template>
    <div class="scroll">
        <a class="scroll-left" :class="hidden ? 'hidden' : ''" data-toggle="tooltip" title="Scroll naar links" @click="moveItems('left')">
            <span class="icon">
                <font-awesome-icon icon="angle-left" />
            </span>
        </a>
        <a class="scroll-right" :class="hidden ? 'hidden' : ''" data-toggle="tooltip" title="Scroll naar rechts" @click="moveItems('right')">
            <span class="icon">
                <font-awesome-icon icon="angle-right" />
            </span>
        </a>
    </div>
</template>

<script>

export default {

    data: () => ({
        hidden: true,
	}),
    
    methods: {
        moveItems(direction){
            this.$emit('move-items', direction);
        },
        scroll(e) {
            const ClientRect = this.$parent.$refs['anchor'].getBoundingClientRect();
            const UserBottom = document.documentElement.scrollTop + window.innerHeight;
            const AnchorStart = ClientRect.top + window.scrollY;
            const distanceToBottom = (UserBottom - AnchorStart) - ClientRect.height;


            if((UserBottom - 75) > AnchorStart && distanceToBottom <= 0)
                this.hidden = false;
            else
                this.hidden = true;
        }

    },
    mounted() {
        document.addEventListener('scroll', (e) => this.scroll(e));
    }

}
</script>

<style lang="scss">

.hidden {
    display: none;
}

.scroll{

    &-left{
        position: fixed;
        left: 0;
        bottom: 0;
        cursor: pointer;
    }
    &-right{
        position: fixed;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }
    .icon{
        color: #d8d8d8;
        font-size: 5em;
    }

}
@media only screen and (max-width: 768px) {
    .scroll{
        .icon{
            color: #d8d8d8;
            font-size: 3em;
        }

    }
}
</style>
