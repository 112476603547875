<template>
    <div class="indexmanager">
        <div class="page_title">
            <h1>Index Manager</h1>
        </div>
        <div class="table_container">
            <div class="indexmanager_table">
                <div class="index_status_header">
                    <div class="index_name">
                        Index
                    </div>
                    <div class="index_health">
                        status
                    </div>
                </div>

                <div class="index_status_list" v-for="index in indexList" :key="index['index']">
                    <div class="index_name">
                        {{ index['index'] }}
                    </div>
                    <div class="index_health" :class="index['health']">
                    </div>
                </div>
            </div>
        </div>
        <div class="index_actions">
            <div class="partijprogramma">
                <div class="title">
                    <h2>Partijprogramma</h2>
                </div>

                <div v-if="indexingProgrammas" class="indexing_status">
                    <div class="statusbar" :style="setProgressbar('partijProgrammaProgress')">
                        <div class="percentage_done">
                            {{partijProgrammaProgress}}%
                        </div>
                    </div>
                    <div class="task_status">
                        {{ partijProgrammaStatus }}
                    </div>
                </div>

                <div class="button_container">
                    <button
                        @click="createIndex('partijprogramma')"
                        class="create_button"
                    >
                        index aanmaken
                    </button>
                    <button
                        @click="deleteIndex('partijprogramma')"
                        class="delete_button"
                    >
                        index verwijderen
                    </button>
                    <button
                        @click="fillProgrammaIndex()"
                        class="fill_button"
                    >
                        index vullen
                    </button>
                </div>
            </div>

            <div class="search_logs">
                <div class="title">
                    <h2>Search_logs</h2>
                </div>

                <div v-if="indexingSearchLogs" class="indexing_status">
                    <div class="statusbar" :style="setProgressbar('searchLogsProgress')">
                        <div class="percentage_done" :style="{ color: searchLogsProgress > 50 ? 'white' : 'black'} ">
                            {{searchLogsProgress}}%
                        </div>
                    </div>
                    <div class="task_status">
                        {{ searchLogsStatus }}
                    </div>
                </div>

                <div class="button_container">
                    <button
                        @click="createIndex('search_logs')"
                        class="create_button"
                    >
                        index aanmaken
                    </button>
                    <button
                        @click="deleteIndex('search_logs')"
                        class="delete_button"
                    >
                        index verwijderen
                    </button>
                    <button
                        @click="fillSearchLogs()"
                        class="fill_button"
                    >
                        index vullen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        indices: {
            type: Array,
        },
        programmas: {
            type: Array,
        },
        search_logs: {
            type: Number,
        }
    },
    data() {
        return {
            indexList: [],

            indexingProgrammas: false,
            partijProgrammaProgress: 0,
            partijProgrammaStatus: '',

            indexingSearchLogs: false,
            searchLogsProgress: 0,
            searchLogsStatus: '',

            taskStatus: '',
        }
    },
    methods: {
        setProgressbar(dataVarName) {
            return `background-image: linear-gradient(90deg, #5cb85c ${this[dataVarName]}%, rgba(0, 0, 0, 0.05) ${this[dataVarName]}% 100%)`;
        },

        async deleteIndex(index) {
            let { data } = await axios.delete('/admin/indexmanager', {
                params: {
                    index
                }
            });

            this.indexList = data;
        },

        async createIndex(index) {
            let { data } = await axios.post('/admin/indexmanager', {
                index
            });

            this.indexList = data;
        },

        async fillProgrammaIndex() {
            this.indexingProgrammas = true;
            this.taskStatus = 'Bezig met initialiseren';
            let i = 0;
            let total = this.programmas.length;

            for(const programma of this.programmas) {
                this.partijProgrammaStatus = `Partijprogramma van ${programma.partij} indexeren...`;

                let { data } = await axios.post('/admin/indexmanager/fillpartijprogramma', {
                    programma
                });

                if(typeof data != 'object')
                    return this.partijProgrammaStatus = 'Inlog verlopen, log opnieuw in en probeer het opnieuw';

                i++;

                this.partijProgrammaProgress = Math.floor(i / total * 100);

                if(this.partijProgrammaProgress == 100) {
                    this.partijProgrammaProgress = 0;
                    this.partijProgrammaStatus = '';
                    this.indexingProgrammas = false;
                }
            }
        },

        async fillSearchLogs() {
            const batchSize = 1000;
            const totalSize = this.search_logs;
            const loopCount = Math.ceil(totalSize / batchSize);

            this.indexingSearchLogs = true;

            for(let i = 0; i < loopCount; i++) {
                this.searchLogsStatus = `Batch ${(i + 1)} van ${loopCount}`;

                let { data } = await axios.post('/admin/indexmanager/fillsearchlogs',
                    {
                        offset: (batchSize * i),
                        limit: batchSize
                    }
                );

                if(data?.success) {
                    this.searchLogsProgress = Math.floor((i + 1) * batchSize / totalSize * 100);

                    if(this.searchLogsProgress == 100) {
                        this.searchLogsProgress = 0;
                        this.indexingSearchLogs = false;
                        this.searchLogsStatus = '';
                    }
                } else {
                    this.taskStatus = 'Indexeren mislukt. Probeer opnieuw of zie console voor details.'
                }
            }

        },

        async getIp() {
            let ip = await fetch('https://checkip.amazonaws.com/');
            console.log(ip.headers);
            fetch("https://checkip.amazonaws.com/").then(res => res.text()).then(data => console.log(data))
        }
    },
    mounted() {
        this.getIp();
        this.indexList = this.indices;
    }
}
</script>

<style lang="scss">
.indexmanager {
    height: 100%;
    padding: 25px 25%;

    .page_title {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .table_container {
        display: flex;
        justify-content: center;

        .indexmanager_table {
            width: 75%;

            .index_status_list, .index_status_header {
                display: flex;
                padding: 6px 12px;

                &:nth-child(odd) {
                    background-color: rgba(black, 0.02);
                }
                &:nth-child(even) {
                    background-color: rgba(black, 0.05);
                }


                .index_name {
                    flex: 80%;
                }

                .index_health {
                    flex: 20%;
                    border-radius: 50%;

                    &.yellow::before {
                        content: '';
                        display: flex;
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                        border-radius: 50%;
                        background: #f0ad4e;
                    }

                    &.green::before {
                        content: '';
                        display: flex;
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                        border-radius: 50%;
                        background: #5cb85c;
                    }

                    &.red::before {
                        content: '';
                        display: flex;
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                        border-radius: 50%;
                        background: #d9534f;
                    }
                }
            }
        }
    }
    .index_actions {

        .partijprogramma, .search_logs {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .title {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .button_container {
                display: flex;
                width: 75%;
                justify-content: space-evenly;
                padding: 20px 10px;
                flex-wrap: wrap;

                button {
                    all: unset;
                    cursor: pointer;
                    padding: 4px 24px;
                    border-radius: 6px;
                }

                .create_button {
                    background-color: #5cb85c;
                    color: white;
                }

                .delete_button {
                    background-color: #d9534f;
                    color: white;
                }

                .fill_button {
                    background-color: #0275d8;
                    color: white;
                }
            }

            .indexing_status {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                .statusbar {
                    // background-image: linear-gradient(90deg, #5cb85c 0%, #e0251b 99% 100%);
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    color: black;
                    border-radius: 12px;
                }
                .task_status {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}


</style>
