<template>
    <div class="container-fluid" ref="anchor">

        <scroll-component @move-items="moveItems" v-if="results.length >=3"/>

        <div class="container-outer">

            <div v-if="results.length">

                <div class="result-item" v-for="partij in results" :key="partij._id">

                    <section v-if="isActive(partij._id)">

                        <div class="card-img">
                            <div class="card-img-top" v-bind:style="{ 'background-image': 'url(' + partij._source.logo_path + ')' }"></div>
                        </div>

                        <div class="card">
                            <span class="close-btn" @click="toggle(partij._id)">
                                <font-awesome-icon icon="times-circle" />
                            </span>
                            <a :href="partij._source.path" class="pdf-link" target="_blank" >
                                <span>
                                    <font-awesome-icon icon="file-pdf" />
                                </span>
                                Bekijk volledig programma als pdf
                            </a>
                            <div class="card-body" v-if="partij.highlight">
                                <div v-for="(highlight, i) in partij.highlight['attachment.content']" :key="i">
                                    <p class="card-text">... <span v-html="highlight"></span> ...</p>
                                    <hr>
                                </div>
                            </div>

                            <p class="no-result" v-else>
                                Geen resultaten gevonden.
                            </p>
                        </div>

                    </section>

                </div>

            </div>

        </div>

    </div>
</template>

<script>

import ScrollComponent from './ScrollComponent.vue';

export default {

    props: ['results'],
    components : {
        'scroll-component' : ScrollComponent
    },

    data: () => {
        return {
            activePartijIds: [],
        };
    },

    methods: {

        toggle(id){
            let indexOf = this.activePartijIds.indexOf(id);

            for(let i = 0; i < this.results.length; i++) {
                if(this.results[i]._id == id) {
                    this.results.splice(i, 1);
                }
            }

            if(indexOf >= 0) {
                this.activePartijIds.splice(indexOf, 1);
            }

            this.setFilters(this.activePartijIds);
        },

        isActive(id) {
            return this.activePartijIds.indexOf(id) >= 0
            ? true
            : false;
        },

        setFilters(filters) {
            this.$root.$emit('global-filters', filters);
        },

        resetActive(results) {
            results.forEach((result) => {
                this.activePartijIds.push(result._id);
            });
        },

        moveItems(direction){

            let items =  document.querySelectorAll('.result-item');
            let content = document.querySelector('.container-outer');
            let scrollMax = content.clientWitch;

            let scrollAmount = content.offsetWidth;

            if ( items.length >= 3 ) {

                let moveDistance = items[0].offsetWidth;

                if(direction == 'left')
                {
                    content.scrollTo({
                        top: 0,
                        left: Math.max(scrollAmount += moveDistance, scrollMax),
                        behavior: 'smooth'

                    });

                } else if ( direction == 'right') {

                    content.scrollTo({
                        top: 0,
                        left: scrollAmount += moveDistance,
                        behavior: 'smooth'
                    });

                }

            }

        }

    },
    mounted() {
        this.results.forEach((result) => {
            this.activePartijIds.push(result._id);
        });

        this.$root.$on('set-active-ids', this.resetActive)

    }


};
</script>

<style lang="scss">

$black : #00212A;

.container-fluid{
    position: relative;
}

.container-outer{
    overflow-x: scroll;
    white-space: nowrap;
    overflow: auto;
    width: 95%;
    padding: 10px;
    text-align: center;
    margin: auto;
    transition: ease-in-out all .5s;

    .result-item{
        display: inline-block;
        width: 500px;
        vertical-align: top;
        white-space: normal;
        margin: 0 10px;
        text-align: left;

        .card{
            padding: 80px 20px 20px;
            border: none;
            position: relative;
            &-img{
                border-radius: 50%;
                width: 200px;
                height: 200px;
                margin: 10px auto -90px;
                position: relative;
                background-color: #fff;
                z-index: 1000;

                &-top{
                    height: 120px;
                    width: 120px;
                    background-size: 80%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    position: absolute;
                    bottom: 40px;
                    left: 40px;

                }
            }
            &-body{
                hr{
                    margin: 40px 0;
                }
            }

            .highlight{
                background-color: rgb(61, 201, 237, .6);
            }

            .close-btn{
                color: #7B8B90;
                font-size: 2em;
                text-align: right;
                position: absolute;
                right: 8px;
                top: -22px;
                cursor: pointer;

            }


            .pdf-link{
                display: block;
                padding: 0 0 0 20px;
                color: #3DC9ED;
                font-size: 1.1em;
                line-height: 1.5;
                span{
                    font-size: 2em;
                    margin-right: 5px;
                }
            }

            .no-result{
                padding: 20px 25px;
            }

        }

    }

}
@media only screen and (max-width: 768px) {
    .container-outer{
        width: 100%;
        padding: 0;
        .result-item{
            margin: 0 5px;
            width: 350px;
            .card{
                &-body{
                    padding: 1rem;
                }
            }
        }

    }
}
</style>
