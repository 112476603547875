<template>
    <div class="container">
        <ul class="parties-box row">
            <li class="form-check col" v-for="item in programma" :key="item.id">
                <input class="form-check-input" type="checkbox" :value="item.id" v-bind:id="'defaultCheck-' + item.id" @input="selectParty(item.id)" v-model="party">
                <label class="form-check-label" v-bind:for="'defaultCheck-' + item.id">
                    <div class="check-input-logo mb-2" v-bind:style="{ 'background-image': 'url('+ 'storage' + item.logo_path + ')' }"></div>
                    {{ item.partij }}
                </label>
            </li>    
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            party : []
        };
    },
    props: ['programma'],
    methods:{
        selectParty(item){
            let indexOf = this.party.indexOf(item);
            if(indexOf >= 0) {
                this.party.splice(indexOf, 1);
            } else {
                this.party.push(item);
            }

            this.$emit('filterselected', this.party);
        },
        setFilters(filters) {
            this.party = filters;
        }
    },
    mounted() {
        this.$root.$on('global-filters', this.setFilters);
    }
};

</script>

<style lang="scss">

.parties-box{
    padding: 0;
    text-align: center;

    .form-check{
        margin: 10px 0;

        .check-input-logo{
            height: 90px;
            width: 90px;
            background-color: #fff;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 3px;
            cursor: pointer;
            transition: ease-in-out all .2s;
        }

        input{
            display: none;
        }
        input[type=checkbox].form-check-input:checked + label.form-check-label {
            border-radius: 50px;
            outline: 2px solid #2FBCDF;
        }
        label{
            width: 90px;
        }

    }

}

@media only screen and (max-width: 768px) {

    .container{
        padding : 0 12px;
    }
    .form-check{
        padding-left: 0;
    }
}

</style>