<template>
    <div class="search-bar">
        <form @submit="doSearch($event)" class="form-inline">
            <div class="search-input-container">
                <input class="form-control search-input" type="search" @input="emitQuery" v-model="search" required placeholder="Voer uw zoekterm(en) in" autofocus>
                <button class="btn btn-dark search-btn" type="submit">
                    <span class="search-icon">
                        <font-awesome-icon icon="search" />
                    </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
			search : ''
        };
    },
    methods: {
		emitQuery(){
            this.$emit('querySearch', this.search);
		},
        doSearch(event){
            event.preventDefault();
            this.$emit('searchSubmit');
        },
		clearSearch(){
			this.search = '';
			this.doSearch();
		},
        setSearchTerm(term) {
            this.search = term;
        }
	},
    mounted() {
        this.$root.$on('set-search-term', this.setSearchTerm)
    }
};
</script>

<style lang="scss">

$black : #00212A;

.search-bar{

    .search-input-container{
        width: 100%;
        background-color: #FFF;
        height: 60px;
        display: flex;
        border-radius: 3px;

        .search-input{
            padding: 30px 20px;
            width: 100%;
            margin-right: 0;
            border: none;
            font-size: 1.2em;

        }

        .search-btn{
            margin-left: 0;
            background-color: $black;
            width: 80px;
            height: 65px;
            margin-top: -2px;
            .search-icon{

                font-size: 1.5em;
            }

        }

    }

}

@media only screen and (max-width: 768px) {
  
    .search-bar{
        .search-input-container{
            .search-input{
                font-size: 1em;
            }
        }
    }

}

</style>
